import React, { useEffect, useRef, useState } from 'react';
import './Stars.css';

// Define a fixed set of star positions and animation delays
const starPositions = Array.from({ length: 50 }, () => ({
  left: `${Math.random() * 100}vw`,
  top: `${Math.random() * 100}vh`,
  animationDelay: `${Math.random() * 10}s`,
  rotationSpeed: `${Math.random() * 10 + 5}s`, // Random rotation speed between 5s and 15s
}));

const Stars: React.FC = () => {
  const [rotation, setRotation] = useState(0);
  const requestId = useRef<number | null>(null);
  const rotationSpeed = '100s'; // Adjust this value to change the rotation speed

  useEffect(() => {
    const startTime = performance.now();
    const rotationDuration = parseFloat(rotationSpeed.replace('s', '')) * 1000;

    const animate = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      const rotation = (elapsedTime / rotationDuration) * 360;
      setRotation(rotation);
      requestId.current = requestAnimationFrame(animate);
    };

    requestId.current = requestAnimationFrame(animate);

    return () => {
      if (requestId.current !== null) {
        cancelAnimationFrame(requestId.current);
      }
    };
  }, [rotationSpeed]);

  return (
    <div className="absolute inset-0 overflow-hidden">
      <div className="stars" style={{ transform: `rotate(${rotation}deg)` }}>
        {starPositions.map((position, i) => (
          <div
            key={i}
            className="star"
            style={{
              animationDelay: position.animationDelay,
              left: position.left,
              top: position.top,
              animationDuration: position.rotationSpeed,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Stars;
