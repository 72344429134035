import React from 'react';
import ComingSoon from './components/ComingSoon';
import { Toaster } from 'react-hot-toast';
import { Analytics } from "@vercel/analytics/react"

const App: React.FC = () => {
  return (
    <>
      {<Analytics />}
      {<ComingSoon />}
      <Toaster position="bottom-center" />
    </>
  );
};

export default App;
